type Endpoints = {
  name: string;
  endpoint: string;
};

export type AmplifyConfigType = {
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_graphqlWssEndpoint: string;
  aws_appsync_region: string;
  API: {
    endpoints: [Endpoints];
  };
};

type SimplifiedConfig = {
  appSyncGraphqlEndpoint: string;
  appSyncGraphqlWssEndpoint: string;
};

const expandConfig = (config: SimplifiedConfig): AmplifyConfigType => {
  return {
    aws_appsync_graphqlEndpoint: config.appSyncGraphqlEndpoint,
    aws_appsync_graphqlWssEndpoint: config.appSyncGraphqlWssEndpoint,
    aws_appsync_region: 'us-east-1',
    API: {
      endpoints: [
        {
          name: 'gql',
          endpoint: config.appSyncGraphqlEndpoint,
        },
      ],
    },
  };
};

const AmplifyConfigBranch: AmplifyConfigType = expandConfig({
  appSyncGraphqlEndpoint:
    'https://7ldc2id5pvgx7iesywuucubbp4.appsync-api.us-east-1.amazonaws.com/graphql',
  appSyncGraphqlWssEndpoint:
    'wss://7ldc2id5pvgx7iesywuucubbp4.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
});

const AmplifyConfigDev: AmplifyConfigType = expandConfig({
  appSyncGraphqlEndpoint:
    'https://j45a5yd475c63ism3fb3zjddaa.appsync-api.us-east-1.amazonaws.com/graphql',
  appSyncGraphqlWssEndpoint:
    'wss://j45a5yd475c63ism3fb3zjddaa.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
});

const AmplifyConfigStaging: AmplifyConfigType = expandConfig({
  appSyncGraphqlEndpoint:
    'https://tg7tjgk5tzejxplqd2lfnkvjz4.appsync-api.us-east-1.amazonaws.com/graphql',
  appSyncGraphqlWssEndpoint:
    'wss://tg7tjgk5tzejxplqd2lfnkvjz4.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
});

const AmplifyConfigQE: AmplifyConfigType = expandConfig({
  appSyncGraphqlEndpoint:
    'https://y7vilaon65dbzceeucm2u6wu7i.appsync-api.us-east-1.amazonaws.com/graphql',
  appSyncGraphqlWssEndpoint:
    'wss://y7vilaon65dbzceeucm2u6wu7i.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
});

const AmplifyConfigProd: AmplifyConfigType = expandConfig({
  appSyncGraphqlEndpoint:
    'https://bxox4bfj6zdqfchb74yze3qlnu.appsync-api.us-east-1.amazonaws.com/graphql',
  appSyncGraphqlWssEndpoint:
    'wss://bxox4bfj6zdqfchb74yze3qlnu.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
});

export {
  AmplifyConfigStaging,
  AmplifyConfigDev,
  AmplifyConfigBranch,
  AmplifyConfigProd,
  AmplifyConfigQE,
};
