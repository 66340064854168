import { StyledButton, ButtonRules } from '@canopysecurity/component-library';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import CondorSpinner from '../../Components/CondorSpinner';
import {
  useGetTagsQuery,
  Tag,
  useGetDeviceTagsQuery,
} from '../../gql/generated';
import { SectionTitleText } from '../../StyledComponents/Typography';
import { CreateTagModal } from './CreateTagModal';
import styled from 'styled-components';
import cutecondor from '../../assets/cute-condor.png';
import { StyledDataGrid } from '../../StyledComponents/Table';
import {
  GridActionsCellItem,
  GridColDef,
  GridPaginationMeta,
  GridPaginationModel,
} from '@mui/x-data-grid-pro';
import { Delete, Edit } from '@mui/icons-material';
import { UpdateTagModal } from './UpdateTagModal';
import { DeleteTagModal } from './DeleteTagModal';
import { Typography } from '@mui/material';

const TableContainer = styled.div`
  margin-top: 3%;
  padding: 0 5% 0 5%;
  height: 40vh;
  margin-bottom: 4em;
`;

function DeviceCountCell({ tag }: { tag: Tag }) {
  const deviceTagsQuery = useGetDeviceTagsQuery({
    variables: {
      input: {
        tagId: tag.tagId,
      },
    },
  });

  const deviceTags = useMemo(() => {
    const hasMore = !!deviceTagsQuery.data?.getDeviceTags?.next;
    const serialNumbers = (
      deviceTagsQuery.data?.getDeviceTags?.results ?? []
    ).map((dT) => dT?.serialNumber);

    if (serialNumbers.length === 0) {
      return '(0 Devices)';
    }
    if (serialNumbers.length === 1) {
      return `(1 Device) ${serialNumbers[0]}`;
    }
    if (serialNumbers.length > 1 && !hasMore) {
      return `(${serialNumbers.length} Devices) ${serialNumbers.join(', ')}`;
    }
    if (serialNumbers.length > 1 && hasMore) {
      return `(${serialNumbers.length}+ Devices) ${serialNumbers
        .slice(0, 5)
        .join(', ')}...`;
    }
    return '';
  }, [deviceTagsQuery.data]);

  return <Typography variant="body2">{deviceTags}</Typography>;
}

export function TagsTable() {
  /**
   * Pagination
   */
  const [next, setNext] = useState<string | null>(null);
  const [page, setPage] = useState<number | null>(null);
  const [limit] = useState<number>(50);

  const paginationDynamoToMuiRef = useRef<{
    nextDynamo: string | null;
    nextMui: number | null;
    prevDynamo: string | null;
    prevMui: number | null;
    currMui: number;
  }>({
    nextDynamo: null,
    nextMui: null,
    prevDynamo: null,
    prevMui: null,
    currMui: 1,
  });

  const {
    data: tagsData,
    loading: loadingTagsData,
    refetch,
  } = useGetTagsQuery({
    variables: {
      input: {
        next,
        limit,
      },
    },
  });
  useEffect(() => {
    if (tagsData) {
      paginationDynamoToMuiRef.current.nextDynamo =
        tagsData.getTags?.next ?? null;
      paginationDynamoToMuiRef.current.prevDynamo =
        tagsData.getTags?.prev ?? null;
    }
  }, [tagsData]);
  const tags = (tagsData?.getTags?.results ?? []).filter(Boolean) as Tag[];

  const [isCreateTagModalVisible, setIsCreateTagModalVisible] =
    useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);
  const [isUpdateTagModalVisible, setIsUpdateTagModalVisible] =
    useState<boolean>(false);
  const [isDeleteTagModalVisible, setIsDeleteTagModalVisible] =
    useState<boolean>(false);

  /**
   * MUI
   */
  const rows = useMemo(() => tags.map((t) => ({ id: t.tagId, ...t })), [tags]);

  /**
   * MUI
   */
  const columns: GridColDef<Tag>[] = useMemo(
    () => [
      {
        field: 'tagName',
        headerName: 'Tag',
        width: 200,
      },
      {
        field: 'deviceCount',
        headerName: 'Device Count',
        flex: 1,
        renderCell: (params) => <DeviceCountCell tag={params.row} />,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 200,
        getActions: (params) => [
          <GridActionsCellItem
            key={params.id}
            showInMenu={false}
            icon={<Edit />}
            onClick={() => {
              setSelectedTag(params.row);
              setIsUpdateTagModalVisible(true);
            }}
            label="Edit Tag"
          />,
          <GridActionsCellItem
            key={params.id}
            showInMenu={false}
            icon={<Delete />}
            onClick={() => {
              setSelectedTag(params.row);
              setIsDeleteTagModalVisible(true);
            }}
            label="Delete Tag"
          />,
        ],
      },
    ],
    [],
  );

  /**
   * MUI
   */
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel,
  ) => {
    const nextDynamo =
      newPaginationModel.page > paginationDynamoToMuiRef.current.currMui
        ? paginationDynamoToMuiRef.current.nextDynamo
        : paginationDynamoToMuiRef.current.prevDynamo;
    const nextPage =
      newPaginationModel.page > paginationDynamoToMuiRef.current.currMui
        ? paginationDynamoToMuiRef.current.currMui + 1
        : paginationDynamoToMuiRef.current.currMui - 1;
    setNext(nextDynamo);
    setPage(nextPage);
  };

  // /**
  //  * MUI
  //  */
  // const page = useMemo(() => {
  //   if (typeof next === 'number' && typeof limit === 'number') {
  //     return Math.floor(next / limit);
  //   }
  //   return 0;
  // }, [next, limit]);

  /**
   * MUI
   */
  const paginationMetaRef = useRef<GridPaginationMeta>();
  const paginationMeta = useMemo(() => {
    const hasNextPage = typeof tagsData?.getTags?.next === 'string';
    if (hasNextPage && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [tagsData]);

  /**
   * MUI
   */
  const rowCountRef = useRef(tagsData?.getTags?.count ?? 0);
  const rowCount = useMemo(() => {
    if (typeof tagsData?.getTags?.count === 'number') {
      rowCountRef.current = tagsData?.getTags?.count;
    }
    return rowCountRef.current;
  }, [tagsData]);
  if (loadingTagsData) {
    return <CondorSpinner />;
  }

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '16px',
          }}
        >
          <SectionTitleText>Tags</SectionTitleText>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              columnGap: '8px',
            }}
          >
            <StyledButton
              onPress={() => {
                setIsCreateTagModalVisible(true);
              }}
              buttonRules={ButtonRules.primaryA}
            >
              Create a Tag
            </StyledButton>
          </div>
        </div>

        <StyledDataGrid
          rows={rows}
          columns={columns}
          loading={loadingTagsData}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          slots={{
            noRowsOverlay: () => (
              <div>
                <img
                  src={cutecondor}
                  alt="No tags available to target on"
                  style={{ maxHeight: '70%' }}
                />
              </div>
            ),
          }}
          paginationMode="server"
          pageSizeOptions={[50]}
          paginationMeta={paginationMeta}
          rowCount={rowCount}
          onPaginationModelChange={handlePaginationModelChange}
          paginationModel={{
            page: page ?? 1,
            pageSize: limit ?? 50,
          }}
        />
      </TableContainer>
      <CreateTagModal
        isVisible={isCreateTagModalVisible}
        onClose={() => setIsCreateTagModalVisible(false)}
        refetch={refetch}
      />
      {selectedTag && (
        <Fragment>
          <UpdateTagModal
            tag={selectedTag}
            isVisible={isUpdateTagModalVisible}
            onClose={() => {
              setIsUpdateTagModalVisible(false);
              setSelectedTag(null);
            }}
            refetch={refetch}
          />
          <DeleteTagModal
            tag={selectedTag}
            isVisible={isDeleteTagModalVisible}
            onClose={() => {
              setIsDeleteTagModalVisible(false);
              setSelectedTag(null);
            }}
            refetch={refetch}
          />
        </Fragment>
      )}
    </>
  );
}
