import { StyledButton, ButtonRules } from '@canopysecurity/component-library';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CondorSpinner from '../../Components/CondorSpinner';
import {
  useGetTagsQuery,
  Tag,
  DeviceTag,
  useGetDeviceTagsQuery,
} from '../../gql/generated';
import { SectionTitleText } from '../../StyledComponents/Typography';
import styled from 'styled-components';
import cutecondor from '../../assets/cute-condor.png';
import { StyledDataGrid } from '../../StyledComponents/Table';
import { AssociateTagModal } from './AssociateTagModal';
import { DisassociateTagModal } from './DisassociateTagModal';
import {
  GridColDef,
  GridPaginationMeta,
  GridPaginationModel,
  GridValidRowModel,
} from '@mui/x-data-grid';

const TableContainer = styled.div`
  margin-top: 3%;
  padding: 0 5% 0 5%;
  height: 40vh;
  margin-bottom: 4em;
`;

type Row = DeviceTag & {
  tags: DeviceTagWithName[];
  id: string;
};

type DeviceTagWithName = DeviceTag & { tagName?: string | null };
export function DeviceTagsTable() {
  /**
   * Pagination
   */
  const [next, setNext] = useState<string | null>(null);
  const [page, setPage] = useState<number | null>(null);
  const [limit] = useState<number>(50);

  const paginationDynamoToMuiRef = useRef<{
    nextDynamo: string | null;
    nextMui: number | null;
    prevDynamo: string | null;
    prevMui: number | null;
    currMui: number;
  }>({
    nextDynamo: null,
    nextMui: null,
    prevDynamo: null,
    prevMui: null,
    currMui: 1,
  });

  const { data: tagsData } = useGetTagsQuery({
    variables: {
      input: {},
    },
  });
  const tags = (tagsData?.getTags?.results ?? []).filter(Boolean) as Tag[];

  const {
    data: deviceTagsData,
    loading: loadingDeviceTagsData,
    refetch: refetchDeviceTags,
  } = useGetDeviceTagsQuery({
    variables: {
      input: {
        next,
        limit,
      },
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (deviceTagsData) {
      paginationDynamoToMuiRef.current.nextDynamo =
        deviceTagsData.getDeviceTags?.next ?? null;
      paginationDynamoToMuiRef.current.prevDynamo =
        deviceTagsData.getDeviceTags?.prev ?? null;
    }
  }, [deviceTagsData]);

  const [isAssociateTagModalVisible, setIsAssociateTagModalVisible] =
    useState<boolean>(false);
  const [isDisassociateTagModalVisible, setIsDisassociateTagModalVisible] =
    useState<boolean>(false);
  /**
   * MUI
   */
  const rows: Row[] = useMemo(
    () =>
      Object.entries(
        (
          (deviceTagsData?.getDeviceTags?.results ?? []).filter(
            Boolean,
          ) as DeviceTag[]
        )
          .map((d) => ({
            ...d,
            tagName:
              tags.find((t) => t.tagId === d.tagId)?.tagName ?? 'Unknown Tag',
          }))
          .reduce(
            (
              acc: Record<
                string,
                {
                  deviceTag: DeviceTag;
                  tags: DeviceTagWithName[];
                }
              >,
              cur,
            ) => {
              if (!cur.serialNumber) {
                return acc;
              }
              if (!acc[cur.serialNumber]) {
                acc[cur.serialNumber] = {
                  deviceTag: cur,
                  tags: [],
                };
              }
              acc[cur.serialNumber].tags.push(cur);
              return acc;
            },
            {},
          ),
      ).map(([serialNumber, { deviceTag, tags: tagsForDevice }]) => ({
        id: deviceTag.canopyDeviceId ?? 'null',
        serialNumber,
        ...deviceTag,
        tags: tagsForDevice,
      })),
    [deviceTagsData?.getDeviceTags?.results, tags],
  );

  const [selectedDeviceTag, setSelectedDeviceTag] = useState<Row | null>(null);
  /**
   * MUI
   */
  const columns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: 'serialNumber',
        headerName: 'Serial Number',
        width: 300,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        flex: 1,
        renderCell: (params) =>
          params.row.tags.map((t) => t.tagName).join(', '),
      },
      {
        field: 'disassociate',
        headerName: 'Disassociate',
        width: 200,
        renderCell: (params) => (
          <StyledButton
            onPress={() => {
              setSelectedDeviceTag(params.row);
              setIsDisassociateTagModalVisible(true);
            }}
            buttonRules={ButtonRules.primaryA}
          >
            Disassociate Tags
          </StyledButton>
        ),
      },
    ],
    [],
  );

  /**
   * MUI
   */
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel,
  ) => {
    const nextDynamo =
      newPaginationModel.page > paginationDynamoToMuiRef.current.currMui
        ? paginationDynamoToMuiRef.current.nextDynamo
        : paginationDynamoToMuiRef.current.prevDynamo;
    const nextPage =
      newPaginationModel.page > paginationDynamoToMuiRef.current.currMui
        ? paginationDynamoToMuiRef.current.currMui + 1
        : paginationDynamoToMuiRef.current.currMui - 1;
    setNext(nextDynamo);
    setPage(nextPage);
  };

  // /**
  //  * MUI
  //  */
  // const page = useMemo(() => {
  //   if (typeof next === 'number' && typeof limit === 'number') {
  //     return Math.floor(next / limit);
  //   }
  //   return 0;
  // }, [next, limit]);

  /**
   * MUI
   */
  const paginationMetaRef = useRef<GridPaginationMeta>();
  const paginationMeta = useMemo(() => {
    const hasNextPage = typeof tagsData?.getTags?.next === 'string';
    if (hasNextPage && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [tagsData]);

  /**
   * MUI
   */
  const rowCountRef = useRef(tagsData?.getTags?.count ?? 0);
  const rowCount = useMemo(() => {
    if (typeof tagsData?.getTags?.count === 'number') {
      rowCountRef.current = tagsData?.getTags?.count;
    }
    return rowCountRef.current;
  }, [tagsData]);

  if (loadingDeviceTagsData) {
    return <CondorSpinner />;
  }

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '16px',
          }}
        >
          <SectionTitleText>Device - Tags Associations</SectionTitleText>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              columnGap: '8px',
            }}
          >
            <StyledButton
              onPress={() => {
                setIsAssociateTagModalVisible(true);
              }}
              buttonRules={ButtonRules.primaryA}
            >
              Associate a Tag with a Device
            </StyledButton>
          </div>
        </div>

        <StyledDataGrid
          rows={rows}
          columns={columns as GridColDef<GridValidRowModel>[]}
          loading={loadingDeviceTagsData}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          slots={{
            noRowsOverlay: () => (
              <div>
                <img
                  src={cutecondor}
                  alt="No devices have been associated with tags"
                  style={{ maxHeight: '70%' }}
                />
              </div>
            ),
          }}
          paginationMode="server"
          pageSizeOptions={[50]}
          paginationMeta={paginationMeta}
          rowCount={rowCount}
          onPaginationModelChange={handlePaginationModelChange}
          paginationModel={{
            page: page ?? 1,
            pageSize: limit ?? 50,
          }}
        />
      </TableContainer>
      <AssociateTagModal
        tags={tags}
        isVisible={isAssociateTagModalVisible}
        onClose={() => setIsAssociateTagModalVisible(false)}
        refetch={refetchDeviceTags}
      />
      {selectedDeviceTag?.serialNumber && (
        <DisassociateTagModal
          tags={selectedDeviceTag.tags.map((d) => ({
            tagId: d.tagId,
            tagName: d.tagName,
          }))}
          serialNumber={selectedDeviceTag.serialNumber}
          isVisible={isDisassociateTagModalVisible}
          onClose={() => setIsDisassociateTagModalVisible(false)}
          refetch={refetchDeviceTags}
        />
      )}
    </>
  );
}
