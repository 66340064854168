import React, { useCallback, useMemo, useState } from 'react';
import pixelRatio from '@canopysecurity/component-library/utils/pixelRatio';
import {
  ModalTitleText,
  ParagraphText,
} from '../../StyledComponents/Typography';
import {
  ButtonRules,
  StyledButton,
  useTheme,
} from '@canopysecurity/component-library';
import Modal from 'react-modal';
import { Tag, useAssociateTagWithDeviceMutation } from '../../gql/generated';
import { ComponentTheme } from '@canopysecurity/component-library/themes/defaultTheme';
import styled from 'styled-components';
import { StatusMessage } from '../../Components/StatusMessage';
import { MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const createStyle = (theme: ComponentTheme) => {
  return {
    button: {
      flex: 1,
      height: 40,
      marginLeft: '8px',
      marginRight: '8px',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.background.surface,
      borderRadius: theme.radii.radiusLarge,
    },
    footerBackgroundContainer: {
      width: '100%',
      paddingTop: '20px',
      paddingHorizontal: '9px',
      backgroundColor: theme.background.surface,
    },
    bodyContent: {
      display: 'flex',
      marginTop: '27px',
      marginBottom: '27px',
      paddingLeft: '17px',
      paddingRight: '17px',
      justifyContent: 'space-between',
    },
  } as const;
};

const FormInput = styled.input`
  height: ${pixelRatio.heightPercentageToDP('3.2')}px;
  width: 100%;
`;

const FormLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${pixelRatio.heightPercentageToDP('.2')}em 0px
    ${pixelRatio.heightPercentageToDP('0.1')}em;
  justify-content: flex-start;
  align-items: center;
`;

export const AssociateTagModal = ({
  tags,
  isVisible,
  onClose,
  refetch,
}: {
  tags: Tag[];
  isVisible: boolean;
  onClose: () => void | Promise<void>;
  refetch: () => void;
}) => {
  const theme = useTheme();
  const styles = createStyle(theme);

  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);
  const allSerialNumbers = useMemo(
    () =>
      serialNumbers.length ? serialNumbers : serialNumber ? [serialNumber] : [],
    [serialNumber, serialNumbers],
  );
  /**
   * If a file is uploaded, use that instead
   */
  const [file, setFile] = useState<File | null>(null);
  const handleSelectFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const uploadedFile = event.target.files?.[0];
      setFile(uploadedFile || null);
      if (uploadedFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContents = e.target?.result?.toString() ?? '';
          console.log(fileContents);
          const serialNumbersFromCsv = fileContents.split('\n');
          console.log();
          setSerialNumbers(serialNumbersFromCsv);
          setSerialNumber('');
        };
        reader.readAsText(uploadedFile);
      }
    },
    [],
  );

  const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.lightGreyRegularE};
  `;

  const [associateTagWithDevice, { loading, error }] =
    useAssociateTagWithDeviceMutation();

  const handleAssociateTag = async () => {
    try {
      if (!allSerialNumbers.length) {
        throw new Error(
          'Must have a serial number or upload a serial number CSV',
        );
      }

      for (const sn of allSerialNumbers) {
        for (const tagId of selectedTagIds) {
          await associateTagWithDevice({
            variables: {
              input: {
                tagId,
                serialNumber: sn,
              },
            },
          });
        }
      }
      refetch();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onClose}
      style={{
        content: {
          paddingTop: 30,
          paddingBottom: 30,
          paddingLeft: 20,
          paddingRight: 20,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          minWidth: '26vw',
          position: undefined,
        },
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 999,
        },
      }}
      ariaHideApp={false}
    >
      <ModalTitleText>Associate a Tag</ModalTitleText>
      <Divider />
      <TextField
        fullWidth
        select
        slotProps={{
          select: {
            multiple: true,
            value: selectedTagIds.length ? selectedTagIds : [''],
            displayEmpty: true,
            onChange: (e) => {
              const latestTagIds =
                typeof e.target.value === 'string'
                  ? e.target.value.split(',')
                  : (e.target.value as string[]).filter((t) => t !== '');

              setSelectedTagIds(latestTagIds.length ? latestTagIds : ['']);
            },
          },
        }}
        variant="outlined"
        required
        placeholder="Select tag(s)"
      >
        <MenuItem disabled value="">
          <em>Select tag(s)</em>
        </MenuItem>
        {tags.map((t, i) => (
          <MenuItem key={t.tagId ?? i} value={t.tagId ?? ''}>
            {t.tagName}
          </MenuItem>
        ))}
      </TextField>
      <FormLabelContainer>
        <FormInput
          placeholder="Enter a serial number"
          maxLength={120}
          value={serialNumber}
          autoCapitalize="none"
          onChange={(event): void => {
            setFile(null);
            setSerialNumbers([]);
            setSerialNumber(event.target.value);
          }}
        />
      </FormLabelContainer>
      <ParagraphText>OR</ParagraphText>
      <LoadingButton
        variant="outlined"
        component="label"
        style={{
          color: theme.primary.main,
          borderColor: theme.primary.main,
        }}
      >
        Upload CSV{file ? ` (${file.name})` : ''}
        <input type="file" hidden accept=".csv" onChange={handleSelectFile} />
      </LoadingButton>
      <Divider />
      {error && (
        <>
          <StatusMessage text={error.message} isError /> <Divider />
        </>
      )}

      <div style={styles.footerBackgroundContainer}>
        <div style={styles.buttonContainer}>
          <StyledButton
            disabled={loading}
            onPress={onClose}
            buttonRules={ButtonRules.secondary}
            style={styles.button}
          >
            Cancel
          </StyledButton>
          <StyledButton
            disabled={loading || !allSerialNumbers.length || !selectedTagIds}
            onPress={handleAssociateTag}
            buttonRules={ButtonRules.primaryA}
            style={styles.button}
          >
            {loading ? 'Associating Tag(s)...' : 'Associate Tag(s)'}
          </StyledButton>
        </div>
      </div>
    </Modal>
  );
};
